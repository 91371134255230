<template>
  <div class="app-content">
    <router-view v-slot="{ Component }" v-if="isRouterAlive">
      <keep-alive>
        <component :is="Component" v-if="$route.meta.keepAlive" />
      </keep-alive>
      <component :is="Component" v-if="!$route.meta.keepAlive" />
    </router-view>
    <!-- <intro-popup v-model:showIntro="showIntro" v-model:introLoading="introLoading" :intro="intro" @toReg="toReg"></intro-popup> -->
  </div>
</template>

<script>
import { reactive, toRefs, computed, watch, getCurrentInstance, onMounted, nextTick} from "vue";
import { useStore } from "vuex";
import web3Data from "@/utils/initWeb3";
import IntroPopup from '@/components/IntroPopup'
import { Toast } from 'vant';
import { useI18n } from "vue-i18n";
import { intro } from "@/storage";
export default {
  components: {
    IntroPopup
  },
  name: 'App',
  setup () {
    onMounted(async () => {
     await web3Data.initWeb3()
    })
    
    // 禁止ios10 以上页面缩放
    document.documentElement.addEventListener('touchstart', function (event) {
      if (event.touches.length > 1) {
        event.preventDefault()
      }
    }, false)
    let lastTouchEnd = 0
    document.addEventListener('touchend', function (event) {
      let now = Date.now()
      if (now - lastTouchEnd <= 300) {
        event.preventDefault()
      }
      lastTouchEnd = now
    }, false)
    // 阻止双指放大
    document.addEventListener('gesturestart', function (event) {
      event.preventDefault()
    })

    const store = useStore()
    const { t } = useI18n()
    const data = reactive({
      locale: computed(() => store.state.locale),
      address: computed(() => store.state.accounts[0]),
      web3: computed(() => store.state.provider),
      showIntro: false,
      intro: intro.get() || '',
      introLoading: false,
      isRouterAlive: true,
      initContract: () => {
      }
    })
    if(data.address) {
      data.initContract()
    }
    watch(() => data.address, (val) => {
      if (val) {
        data.initContract()
      }
    })
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
}
</script>

<style lang="less">
@import './assets/style/common.less';
.app-content {
  max-width: 640px; /*no*/
  margin: 0 auto;
}
</style>
