import { Locale } from 'vant';
import zhTW from 'vant/lib/locale/lang/zh-TW';
import enUS from 'vant/lib/locale/lang/en-US';
import hiIN from 'vant/lib/locale/lang/hi-IN';
import esES from 'vant/lib/locale/lang/es-ES';
import jaJP from 'vant/lib/locale/lang/ja-JP';
import koKR from 'vant/lib/locale/lang/ko-KR';

function changeLang(newlang) {
  switch (newlang) {
    case "cht":
      Locale.use('zh-TW', zhTW);
      break;
    case "en":
      Locale.use('en-US', enUS);
      break;
    case "hi":
      Locale.use('hi-IN', hiIN);
      break;
    case "ja":
      Locale.use('ja-JP', jaJP);
      break;
    case "ko":
      Locale.use('ko-KR', koKR);
      break;
    case "es":
      Locale.use('es-ES', esES);
      break;
  }
}

export function Local(mylang) {
  changeLang(mylang)
}
