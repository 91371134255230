import Web3 from "web3";
import store from "../store/index"
import { Toast } from 'vant';
import { i18n } from "../utils/i18n"
import { configChainId } from "./config"
import { count } from "../storage"
const web3Data = {
  web3: {},
  t: i18n.global.t,
  factoryContract: {},
  web3Provider: {},
  initWeb3: async () => {
    if (window.ethereum) {
      web3Data.web3Provider = window.ethereum;
      web3Data.web3 = new Web3(web3Data.web3Provider);
      try {
        await window.ethereum.enable();
      } catch (error) {
        Promise.reject(error)
      }
    } else {
      if (window.web3) {
        web3Data.web3Provider = window.web3.currentProvider;
        web3Data.web3 = new Web3(web3Data.web3Provider);
      } else {
        let num = count.get() || 0
        if (Number(num) < 5) {
          count.set(Number(num) + 1)
          setTimeout(() => {
            web3Data.initWeb3()
          }, 500)
        } else {
          Toast(web3Data.t('暫未檢測到錢包插件，請安裝MetaMask錢包，或在imtoken裡打開'))
        }
      }
    }
    let id = await web3Data.web3.eth.net.getId()
    if (id !== configChainId) {
      Toast(web3Data.t('請切換到幣安主網絡'))
      return
    }
    store.commit("updateProvider", web3Data.web3);
    web3Data.web3.eth.getAccounts().then((accounts) => {
      store.commit("updateAccount", accounts);
    });
    window.ethereum.on("accountsChanged", (accounts) => {
      console.log(accounts);
      location.reload()
      // store.commit("updateAccount", accounts);
    })
    web3Data.web3Provider.on("chainChanged", (chainId) => {
      console.log(chainId);
      location.reload()
      // if (chainId != `0x${configChainId.toString(16)}`) {
      //   store.commit("updateAccount", [])
      //   Toast(web3Data.t('請切換到幣安主網絡'))
      // } else {
      //   store.commit("updateProvider", web3Data.web3);
      //   web3Data.web3.eth.getAccounts().then((accounts) => {
      //     store.commit("updateAccount", accounts);
      //   });
      // }
    });
  }
}

export default web3Data;
