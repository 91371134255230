import axios from "axios";
// import qs from "qs";
import md5 from 'md5'
// import { Toast } from 'vant';
import { getLang } from "./i18n"
import Web3 from "web3";

let apiKey = 'ada3ab99dba28fd7e729a6b9d410cab0'

const http = axios.create();
http.defaults.withCredentials = false;
http.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
http.interceptors.request.use(
  config => {
    if (config.method === "post") {
      if (config.data && config.data.constructor === FormData) {
        return config;
      }
      if (!config.data) {
        config.data = {};
      }
      if (config.data.address) {
        config.data.address = Web3.utils.toChecksumAddress(config.data.address)
      }
      config.data.language = getLang().toLowerCase()
      Object.keys(config.data).forEach(key => {
        if (typeof config.data[key] !== 'string') {
          config.data[key] = JSON.stringify(config.data[key])
        }
      })
      let sortObj = {}
      Object.keys(config.data)
        .forEach(key => {
          sortObj[key] = config.data[key]
        })
      let str = JSON.stringify(sortObj) + apiKey
      let sign = md5(str).toLowerCase()
      config.data.sign = sign
      // config.data = qs.stringify(config.data)
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  response => {
    let data = response.data;
    if (typeof data === "object" && data.status !== 1) {
      // Toast(data.info);
      return Promise.reject(response.data);
    }
    return response.data;
  },
  error => {
    return Promise.reject(error);
  }
);
export default http;
