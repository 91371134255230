import { createStore } from "vuex";
export default createStore({
  state: {
    accounts: [],
    provider: null,
    locale: '',
  },
  mutations: {
    updateAccount(state, value) {
      state.accounts = value;
    },
    updateProvider(state, value) {
      state.provider = value;
    },
    updateLocale(state, value) {
      state.locale = value;
    }
  },
  actions: {},
  modules: {}
});
