<template>
  <van-popup v-model:show="isShow" :style="{ background: 'transparent', width: '100%' }" :close-on-click-overlay="false">
    <div class="popup-style">
      <div class="popup-header">{{$t('請填寫推薦人')}}</div>
      <div class="input-box">
        <van-field type="text" :placeholder="$t('請輸入你的推薦人錢包地址')" input-align="center" v-model="inputReferer" />
      </div>
      <div class="popup-btns flex-center">
        <van-button 
          type="primary"
          class="btn" 
          :disabled="loading"
          :loading="loading" 
          :loading-text="$t('確定')" 
          @click="toReg" 
        >{{$t('確定')}}</van-button>
      </div>
    </div>
  </van-popup>
</template>
<script>
import { reactive, toRefs, computed } from "vue";
import { Toast } from 'vant';
import { useI18n } from "vue-i18n";
export default {
  props: {
    showIntro: {
      type: Boolean,
      defalut: false
    },
    intro: {
      type: String
    },
    introLoading: {
      type: Boolean,
      defalut: false
    },
  },
  emits: ['update:showIntro', 'toReg', 'update:introLoading'],
  setup (props, {emit}) {
    const { t } = useI18n()
    const data = reactive({
      isShow: computed({
        get () {
          return props.showIntro
        },
        set (value) {
          emit('update:showIntro', value)
        }
      }),
      loading: computed({
        get () {
          return props.introLoading
        },
        set (value) {
          emit('update:introLoading', value)
        }
      }),
      inputReferer: props.intro || '',
      toReg: () => {
        if (!data.inputReferer) {
          Toast(t('請輸入邀請地址'))
          return
        }
        emit('toReg', data.inputReferer)
      }
    })
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
}
</script>
<style lang="less" scoped>
.popup-style {
  width: 80%;
  margin: 0 auto;
  background: #FFFFFF;
  border-radius: 15px;
  .popup-header {
    text-align: center;
    line-height: 54px;
    color: #280D5F;
    font-size: 16px;
    font-weight: bold;
    background: linear-gradient(90deg, #F2EDF3 0%, #E8F1F5 100%);
    border-radius: 15px 15px 0px 0px;
  }
  .input-box {
    margin: 37px 19px;
    .van-cell {
      background: #F7F3FF;
      border: 1px solid #E7E3EB;
      border-radius: 5px;
      height: 40px;
      padding: 7px 16px;
      font-size: 14px;
    }
  }
  .popup-btns {
    padding: 0 0 24px;
    .btn {
      min-width: 168px;
      height: 36px;
      border-radius: 10px;
      box-shadow: 0px 4px 0px 0px rgba(31, 199, 212, 0.58);
      padding: 0 12px;
      font-size: 15px;
      font-weight: bold;
    }
  }
}
</style>
