import 'lib-flexible/flexible.js'
import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";
import Vant from 'vant';
import 'vant/lib/index.css';
import { intro, locale, count } from "./storage"
import { i18n, setLang } from "./utils/i18n"
import dateformat from "dateformat"
import http from "./utils/http";

count.set(0)

let lang = 'en'
if (['cht', 'en', 'hi', 'ja', 'ko', 'ar', 'es'].includes(locale.get())) {
  console.log(1)
  lang = locale.get()
} else {
  let navLang = 'en'
  // if (navigator.language.toLowerCase().includes('zh')) {
  //   navLang = 'cht'
  // }
  lang = navLang
}
setLang(lang)

// 返回上一頁
const goBack = () => {
  if (history.length > 0) {
    if (history.state.back) {
      router.go(-1)
    } else {
      router.push({name: 'Index'}) 
    }
  }
}
let queryParse = (str) => {
  if (!str || str === '0') {
    return {}
  }
  let dataArr = decodeURIComponent(str).split('&')
  let params = {}
  dataArr.forEach(query => {
    let queryItem = query.split('=')
    if (queryItem.length === 1) {
      params.id = queryItem[0]
    } else {
      params[queryItem[0]] = queryItem[1]
    }
  })
  return params
}

let href = window.location.href.replace(/#/g, '').replace(/\?utm_source=tokenpocket/g, '')
if (href.includes('?')) {
  let search = href.slice(href.indexOf('?') + 1, href.length)
  let params = queryParse(search)
  if (params.intro) {
    // 是否有推薦邀請碼
    intro.set(params.intro)
  }
}

const app = createApp(App);
app.config.globalProperties.$goBack = goBack;
app.config.globalProperties.$dateformat = dateformat;
app.config.globalProperties.$http = http

app
  .use(router)
  .use(store)
  .use(i18n)
  .use(Vant)
  .mount('#app');
