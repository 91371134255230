import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Index",
    component: () => import("@/views/index"),
    meta: {
      title: "",
      // keepAlive: true
    },
  },
  {
    path: "/sgt",
    name: "Sgt",
    component: () => import("@/views/index/index2"),
  },
  {
    path: "/order",
    name: "Order",
    component: () => import("@/views/order/index"),
  },
  {
    path: "/order-staking",
    name: "OrderStaking",
    component: () => import("@/views/order/stakingHistory"),
  },
  {
    path: "/staking-sgt",
    name: "StakingSgt",
    component: () => import("@/views/index/stakingSgtReward"),
  },
  {
    path: "/staking-ene",
    name: "StakingEne",
    component: () => import("@/views/index/stakingEneReward"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});

export default router;
