import { createI18n } from "vue-i18n"
import { locale } from "../storage"
import store from "../store"
import zh_tw from "../locales/zh-tw.json"
import en from "../locales/en.json"
import hi from "../locales/hi.json"
import ja from "../locales/ja.json"
import ko from "../locales/ko.json"
import es from "../locales/es.json"
import { Local } from "./vantLocale.js"
export const i18n = createI18n({
  locale: 'en',
  messages: {
    'cht': {
      ...zh_tw
    },
    'en': {
      ...en
    },
    'hi': {
      ...hi
    },
    'ja': {
      ...ja
    },
    'ko': {
      ...ko
    },
    'es': {
      ...es
    }
  }
})

export const setLang = (lang) => {
  i18n.global.locale = lang
  store.commit('updateLocale', lang)
  locale.set(lang)
  Local(lang)
}

export const getLang = () => {
  return i18n && i18n.global.locale
}
