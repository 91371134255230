const config = {
  // mumbai testnet 80001
  // SGTTokenAddress: "0x72251482b29e934B4D9583c1e8365559B06B6E19",
  // ENETokenAddress: "0x53eA2bab861016ac928F29638b441Ec5734c8e0E",
  // StakingAddress: "0x1054819D0341aF5A691108ACFBe8f56549518F28",
  // PledgeAddress: "0xbc5e9FD92447Bb09650641CDE2e2Bd2958cfAFFf",
  //
  //      same as SGTTOkenAddress temporarily
  // LPTokenAddress: "0x72251482b29e934B4D9583c1e8365559B06B6E19",
  //
  // bsc network 56
  LPTokenAddress: "0x0b7D8a953932CC6BBAD6F96a81Bf69dEb8F1e746",
  PledgeAddress: "0xbc5e9FD92447Bb09650641CDE2e2Bd2958cfAFFf",
  ENETokenAddress: "0x4a71a0DE094b2afbFe88FdB6b2a3825B25B2dbCC",
  SGTTokenAddress: "0xd7c8c78245C0b5fEde797f899C77CD6A18d4dd40",
  StakingAddress: "0x0673Df93BA0c2367EdDcB9F003da1e058afb760F",
};

// const configChainId = 80001;
const configChainId = 56;

export { config, configChainId };
